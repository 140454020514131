// Used for Team and Event MyPage "All Participants" pages

h2 + a {
  font-weight: 700;
  font-size: 0.9em;
  border-bottom: none;

  .icon {
    width: 16px;
    height: 21px;
    vertical-align: top;
    margin: 1px 2px 0 0;
  }
}

ol {
  list-style: none;

  @media (min-width: $large) {
    margin-bottom: 0;
  }

  li {
    padding: 0.5em 0;
    border-top: 1px solid $sbf-grey;
  }

  @media (max-width: $custom-bp - 1) {
    li:first-child {
      border-top: none;
      padding: 0 0 0.5em;
      margin: -20px 0 0;
    }
  }

  .flex {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 10px 0;
    clear: both;

    @media (min-width: $large) {
      flex-flow: row nowrap;
      padding: 20px 0;
    }

    &__image {
      .flex__img {
        width: 112px;
        height: 112px;
        background: white;
        padding: 3px;
        margin-right: 15px;
        border: 3px solid white;
        box-shadow:
          0 0 0 1px #CCC,
          0 11px 0 -5px rgba(0, 0, 0, 0.2);
        min-width: 0;
        overflow: hidden;
        font-size: 0;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
          }
        }

        a:link,
        a:visited {
          border-bottom: none;
          font-weight: 500;
        }

        &:hover {
          transform: translateY(-3px);
          box-shadow:
            0 0 0 1px #CCC,
            0 14px 0 -5px rgba(0, 0, 0, 0.2);
        }
      }
    }

    &__body {
      flex: 1;

      p {
        font-family: $sansSerifFamily;
        margin: 0;
        font-size: 0.9em;

        &.heavy {
          font-weight: 700;
          margin-bottom: 8px;
        }
      }

      .name {
        color: $sbf-blue;
        margin: 0;
        text-transform: capitalize;

        .count {
          color: $sbf-dark-grey;
          position: relative;
          left: 0;
          font-size: 1.2em;
        }
      }

      .name-black {
        color: black;
        margin: 0;
        text-transform: capitalize;

        .count {
          color: $sbf-dark-grey;
          position: relative;
          left: 0;
          font-size: 1.2em;
        }
      }

      a {
        text-decoration: underline;
        border: none;
      }
    }

    &__action {
      .buttons {
        float: right;
      }

      .join-button {
        @include sbf-button($btnType: "sbf-blue", $btnSize: "small");
      }

      .button--green {
        @include sbf-button($btnType: "sbf-dark-green", $btnSize: "small");
      }

      .button {
        margin: 5px 0 5px 5px;
        text-align: center;

        @media (min-width: $custom-bp) and (max-width: 850px) {
          display: block;
        }
      }
    }
  }

  @media (max-width: $small - 1) {
    .flex {
      display: block;

      &__image {
        float: left;
      }

      &__body {
        font-size: 0.9em;
        padding-left: 127px; // fixes issue with fundraiser info wrapping around image
      }

      &__action {
        padding-left: 127px;

        .button {
          margin-left: 0;
        }
      }

      .flex__action::after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
      }
    }
  }
}
