$svg-icon-path: 'images/';

@import "~sbf-design-system/scss/base";
@import "../websockets/websockets";

$custom-bp: 780px;

// Utility Classes
.mobile-only {
  display: inline-block;

  @media (min-width: 989px) {
    display: none;
  }
}

.ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.condensed-header {
  margin: 0 0 0.5em 0;
}

.tk-editable-ui {
  display: none;
}

/**********************
* Header override defaults - Signed out users
* Hide Donate Button / Search Input Field / Subnav
***********************/

.old-header__right-block {
  top: -28px;

  @media (min-width: $medium) {
    top: -6px;
  }

  .button { display: none; }

  .old-footer__nav {
    padding: 0;
    top: 0;

    .subnav {
      display: none;
    }
  }

  .old-header__search {
    @media (min-width: $medium) {
      visibility: hidden;
      width: 0;
      margin: 0;
      overflow: hidden;
    }
  }
}

// Counter default styles on <header> element in main.scss
[role="main"] {
  margin: -25px 0 0 0;

  @media (min-width: $medium) {
    margin: -48px 0 0 0;
  }
}

/**********************
* Top Head Section
***********************/
.mypage-header-image {
  background-size: auto 275px;
  background-position: center top;
  background-repeat: no-repeat;
  background-color: $sbf-white;

  @media (min-width: $custom-bp) {
    background-size: cover;
    background-position: center;
    background-color: black;
  }
}

.mypage__header {
  padding: 200px 0 1px 0;
  margin: 0 -8px;
  color: $sbf-black;
  border-bottom: 1px solid $sbf-grey;

  @media (min-width: $custom-bp) {
    padding: 200px 0 40px;
    color: $sbf-white;
  }

  &::after,
  &::before {
    content: '';
    width: 100%;
    height: 275px;
    position: absolute;
    top: 0;
    left: 0;

    @media (min-width: $custom-bp) {
      height: 100%;
    }
  }

  &::before {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.05) 1%, rgba(0, 0, 0, 0.6) 100%);
  }

  &::after {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50%, #222 150%);
  }

  .mypage-private-event-check-overlay p {
    text-align: center;
  }

  .event-id {
    display: none;

    @media (min-width: $custom-bp) {
      display: block;
      font-size: 12px;
      color: $sbf-grey;
      letter-spacing: 1px;
      font-weight: 600;
      z-index: 1;
      min-width: 5px;
      text-align: right;
      transform: translateY(-160px);
      padding: 0 24px;
      margin: 0 auto;
    }
  }

  .flag {
    font-size: 0.9em;
    padding: 34px 24px;
    z-index: 5;
    display: block;

    @media (min-width: $custom-bp) {
      padding: 34px 16px;
    }

    .flag__body {
      .event-cancer-message-all-events {
        @include sbf-body('mini-sans');

        font-weight: 600;
        letter-spacing: 0.1em;
        margin: 14px 0 0 0;

        @media (min-width: $small) {
          @include sbf-body('small-sans');

          letter-spacing: 0.1em;
        }
      }

      .private-event-label {
        @include sbf-heading('headline-12-sans');

        display: inline-block;
        color: $sbf-black;
        background-color: $sbf-yellow;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 2.4px;
        text-align: center;
        padding: 4px 6px;
        margin: 0;
      }

      .private-event-tooltip-button {
        display: inline-block;
        font-size: 10px;
        font-weight: bold;
        color: $sbf-white;
        height: 13px;
        width: 13px;
        border-radius: 50%;
        background-color: $sbf-light-blue;
        background-clip: border-box;
        text-align: center;
        vertical-align: super;
        cursor: pointer;
        line-height: 10px;
        padding: 2px;
        margin: 0 0 0 4px;

        @media (min-width: $custom-bp) {
          color: $sbf-black;
          background-color: #FFF;
        }
      }

      h1 {
        @include sbf-heading('headline-24-sans');

        margin: 8px 0 8px 0;
      }

      p {
        margin: 0;
        font-weight: 500;
      }

      .more-info {
        margin: 0;
        padding: 0 5px 0 0;
        display: inline-block;
        position: relative;
        font-size: 1em;
      }

      .fund-info {
        margin: 0 0 10px 0;
      }

      .event-information-top-section {
        max-width: 400px;
        margin: 0 auto;

        @media (min-width: $custom-bp) {
          margin: 0;
        }
      }
    }

    .flag__image {
      margin: -75px auto 50px auto;
      padding: 0;

      @media (min-width: $custom-bp) {
        margin: 0;
        padding: 0 16px 0 0;
      }

      .photo-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 200px;
        box-shadow: 0 12px 0 -6px #858585;
        background-color: #FFF;
        padding: 5px;
        margin: 0 auto;

        @media (min-width: $custom-bp) {
          float: left;
          box-shadow: none;
        }

        img {
          max-height: 100%;
          max-width: 100%;
        }
      }

      .contact-buttons {
        display: none;
      }
    }

    .flag__image,
    .flag__body {
      display: block;
      text-align: center;

      @media (min-width: $custom-bp) {
        display: table-cell;
        vertical-align: middle;
        text-align: left;
      }
    }
  }

  a {
    border: none;
    text-decoration: underline;
    font-weight: 600;

    @media (min-width: $custom-bp) {
      @include link($sbf-dark-yellow);
    }
  }

  // Tooltip
  .contact-overlay-positioning {
    display: inline-block;
    position: relative;

    .contact-overlay {
      &::after {
        right: 43%;
        border-color: #E3E3E3;
      }

      a {
        @include link($sbf-blue);

        text-decoration: underline;
        font-weight: 600;
        border: none;
        display: inline;
      }

      .white-list {
        background: #E3E3E3;
      }

      li {
        padding: 8px 8px 8px 12px;
        font-size: 12px;
        color: #636363;
        font-weight: 500;
        border-bottom: 2.5px solid #C2C2C2;
        white-space: nowrap;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .primary-contact-overlay {
      top: 35px;
      left: -67px;

      @media (min-width: 414px) {
        top: 55px;
        left: -66px;
      }

      @media (min-width: 768px) {
        top: 55px;
        left: -69px;
      }
    }

    .secondary-contact-overlay {
      top: 34px;
      left: -38px;

      @media (min-width: 375px) {
        left: -65px;
      }

      @media (min-width: 414px) {
        top: 34px;
        left: -66px;
      }
    }
  }

  .contact-overlay-bottom-removal li {
    border-bottom: 0;
  }
}

.fancy-buttons__inner {
  padding: 0;
  text-align: right;
}

.fancy-buttons {
  display: none;
  position: absolute;
  bottom: 34px;
  right: 24px;

  @media (min-width: $x-large) {
    display: block;
    right: 23px;
  }

  a.button {
    min-width: 150px;
    text-align: center;
    margin: 0 0 0 16px;
    text-decoration: none;
  }

  .join-button {
    @include sbf-button($btnType: "sbf-blue");
  }

  .donate-button {
    @include sbf-button($btnType: "sbf-dark-green");
  }

  .link-give-by {
    display: block;
    text-align: center;
    margin: 4px 0 0 16px;
  }
}

@import 'sticky-header';

.mypage__content {
  padding: 36px 0;

  p {
    line-height: 1.8;
  }

  @media (min-width: $custom-bp) {
    padding: 70px 0;

    > div {
      display: flex;
      flex-flow: row wrap;
    }
  }
}

.mypage_stat_callout {
  display: none;
}

/**********************
* Sidebar
***********************/
.mypage__sidebar {
  padding: 0 24px;
  min-width: 300px;
  width: 100%;
  text-align: center;

  @media (min-width: $custom-bp) {
    padding: 0 24px 0 6px;
    text-align: left;
    width: 40%;
    flex: 4;
  }

  @media (min-width: $large) {
    padding: 0 32px 0 8px;
  }

  @media (min-width: 1100px) {
    padding: 0 32px 0 15px;
  }

  p {
    max-width: 350px;
    margin: 0 auto 25px auto;

    @media (min-width: $custom-bp) {
      font-size: 0.8em;
    }
  }

  &--bottom {
    order: 2;
  }

  a {
    @include link($sbf-blue);

    text-decoration: underline;
    font-weight: 600;
    border: none;
  }

  .button {
    text-decoration: none;

    &:hover,
    &:focus {
      color: $sbf-white;
    }
  }

  .event-id {
    display: block;

    @media (min-width: $custom-bp) {
      display: none;
    }
  }

  .sidebar-stats {
    display: none;

    @media (min-width: $custom-bp) {
      display: block;
    }
  }
}

// Anniversary Callout
.mobile-callout {
  display: block;

  @media (min-width: $medium) {
    display: none;
  }
}

.desktop-callout {
  @media (min-width: $medium) {
    display: block;
  }

  display: none;
}

.anniversary-callout {
  display: block;
  border: 1px solid #DBDBDB;
  background-color: $sbf-white;
  padding: 0 20px;
  margin: 25px -8px 0;
  text-align: center;

  .logo-anniversary {
    text-align: center;
    padding-top: 24px;
  }

  svg {
    max-height: 100px;
  }

  @media (min-width: $medium) {
    padding: 0 49px;
    margin: 25px 0 0 0;
  }
}

/**********************
* Sidebar - Fundraising Section
***********************/
.mypage__fundraising {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  text-align: left;
  margin: 0 auto;
  max-width: 350px;

  @media (min-width: $custom-bp) {
    width: 100%;
  }

  @media (min-width: $large) {
    padding: 16px;
    background: $sbf-white;
    border: 1px solid $sbf-grey;
    margin: 0;
  }

  .button {
    flex: 1;
    text-align: center;
    margin: 0 0 24px 0;

    &.mobile-only {
      margin: 0 20px 10px 0;
    }

    &.button--green {
      background: $sbf-dark-green;
    }
  }

  .join-button {
    @include sbf-button($btnType: "sbf-blue", $btnSize: "small");

    @media (min-width: $x-large) {
      display: none;
    }
  }

  .donate-button {
    @include sbf-button($btnType: "sbf-dark-green", $btnSize: "small");
  }

  .header-only {
    display: none;
  }

  .tk-editable-ui {
    display: none;
  }

  &.is-editable {
    .tk-editable-ui {
      display: none;

      @media (min-width: $large) {
        display: block;
      }
    }
  }

  .mypage__fundraising__give-by-link {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 12px auto 0 auto;

    @media (min-width: $custom-bp) {
      margin: 0 auto;
    }

    p,
    ul {
      font-family: $sourceSans;
    }

    p {
      font-size: 0.9em;
      margin: 0;
    }

    ul {
      display: flex;
      gap: 8px;
      margin: 0 0 0 4px;
      list-style-type: none;
    }
  }
}

.dashboard__fundraising {
  width: 100%;

  progress,
  progress[role] {
    appearance: none;
    -webkit-appearance: none;
    border: none;
    width: 100%;
    height: 30px;
    box-shadow: 0 0 2px #AAA;
    background-color: #206A3F;
    background-size: auto;
  }

  progress::-webkit-progress-bar {
    background: #206A3F;
  }

  progress {
    color: $sbf-dark-green;
  }

  progress::-moz-progress-bar {
    background: $sbf-dark-green;
  }

  progress::-webkit-progress-value {
    background: $sbf-dark-green;
  }

  progress[value] {
    background: #E4EAF0;
    height: 12px;
    margin: 0 0 24px 0;

    &::-webkit-progress-bar {
      background: #E4EAF0;
    }
  }
}

.mypage__fundraising--vertical.none-editable {
  .fundraising__container {
    margin: 0 auto;
  }
}

.fundraising__raised {
  color: $sbf-dark-green;
  margin: 0 0 5px 0;
}

.fundraising__goal {
  font-size: 0.75em;
  letter-spacing: 0.5px;
  font-weight: 500;
  line-height: 2;
  margin: 0;
}

.fundraising__controls {
  align-self: flex-start;
  width: 100%;
  line-height: 1.6;
  text-align: center;
  margin: -0.3em 0 -0.7em 0;

  @media (min-width: $custom-bp) {
    margin: -0.3em 0 0 0;
  }

  @media (min-width: $x-large) {
    width: auto;
    text-align: left;
    margin: -0.3em 0 0 16px;
    padding: 0 0 0 16px;
    border-left: 1px solid #CCC;
  }

  p {
    font-size: 0.9em;
    margin: 0 auto;
  }

  .donate-by-phone-large {
    display: inline;

    @media (min-width: $x-large) {
      display: none;
    }
  }

  .donate-by-phone-small {
    display: none;

    @media (min-width: $x-large) {
      display: inline;
    }
  }

  .donation-form-download {
    display: inline;
    font-size: 0.9em;
  }
}

/**********************
* Sidebar - Fundraising Stats (Participants / Teams / Donors)
***********************/
.fundraising__stats {
  margin: 40px 0 0 0;
  text-align: left;

  &.donors {
    li {
      &:nth-child(n + 6) {
        display: none;
      }
    }
  }

  .card-header {
    display: inline-block;

    .subheading {
      display: none;
    }
  }

  .list-headers {
    display: none;
  }

  h3 {
    display: inline-block;
  }

  ol {
    counter-reset: aCounter;
    list-style: none;
  }

  li {
    counter-increment: aCounter;
    padding: 0 0 8px 16px;
    z-index: 1;
    display: flex;
    flex-flow: row nowrap;
    font-size: 14px;

    &::before {
      content: counter(aCounter) '. ';
      color: $sbf-black;
      position: absolute;
      left: 0;
    }

    .donor__name,
    span.totals__name {
      word-break: break-word;
    }

    span.dash {
      flex: 1;
      border-bottom: 1px solid $sbf-grey;
      margin: 0 5px 5px 5px;
    }

    span,
    a {
      display: inline-block;
      padding: 0;
    }

    a {
      max-width: 55%;
      text-decoration: underline;
      font-weight: 600;
      border: none;

      @extend .ellipses;
    }

    span.totals__name {
      max-width: 55%;

      @extend .ellipses;
    }
  }

  .totals__amount {
    float: right;
    color: $sbf-black;
  }
}

.fundraising__stats-view-all {
  float: right;
  font-size: 14px;
  transform: translateY(4px);
  text-decoration: underline;
}

.mobile-stats {
  padding: 8px 24px 20px 24px;
  margin: 0 -7px;
  background: $sbf-white;
  border-top: 1px solid $sbf-grey;
  border-bottom: 1px solid $sbf-grey;

  a {
    text-decoration: underline;
    font-weight: 600;
    border: none;
  }

  @media (min-width: $custom-bp) {
    display: none;
  }
}

.stats--red {
  a {
    @include link($sbf-dark-pink);

    text-decoration: underline;
    font-weight: 600;
    border: none;
  }
}

.stats--green {
  a {
    @include link($sbf-dark-green);

    text-decoration: underline;
    font-weight: 600;
    border: none;
  }
}

/**********************
* Sidebar - National Partner
***********************/
.national-partner {
  margin: 40px 0 0 0;

  .partner-logo {
    text-align: center;
  }

  svg {
    max-height: 80px;
  }
}

// Main Column
.mypage__main-column {
  padding: 32px 16px 0 16px;
  min-width: 300px;
  width: 100%;

  @media (min-width: $small) {
    min-width: 350px;
  }

  @media (min-width: $custom-bp) {
    width: 60%;
    flex: 7;
    padding: 0 16px 0 24px;
    box-shadow: -51px -1em 0 -50px #CCC;
  }

  @media (min-width: $large) {
    padding: 0 15px 0 32px;
  }

  @media (min-width: $x-large) {
    padding: 0 24px 0 32px;
  }

  h2 {
    margin: 30px 0 6px 0;
    text-align: center;
    font-weight: 600;

    @media (min-width: $custom-bp) {
      margin: 0 0 8px 0;
      text-align: left;
    }
  }

  p {
    font-size: 1em;
    font-family: $serifFamily;
  }
}

/**********************
* Main Column - Copy
***********************/
.mypage__copy {
  background: $sbf-white;
  margin: 0 -22px 24px -22px;
  padding: 32px 24px;
  border-bottom: 1px solid $sbf-grey;
  border-top: 1px solid $sbf-grey;

  @media (min-width: $custom-bp) {
    background: transparent;
    margin: 0;
    padding: 0;
    border: 0;
  }

  h2 {
    text-align: center;

    @media (min-width: $custom-bp) {
      text-align: left;
    }
  }
}

.contact-buttons {
  font-family: $serifFamily;
  color: $sbf-black;
  font-weight: 500;

  .button {
    border-radius: 50%;
    padding: 6px 4px 2px 4px;
    width: 30px;
    height: 30px;
    text-align: center;
    margin: 0 0 0 4px;
    transform: translateY(2px);
  }

  .icon {
    height: 18px;
    width: 18px;
  }

  &.buttons-mobile {
    margin: 12px 0;

    @media (min-width: $custom-bp) {
      display: none;
    }

    span.find-us {
      display: none;
    }
  }

  &.buttons-desktop {
    float: right;
    display: none;
    margin: 0 0 0 24px;

    @media (min-width: $custom-bp) {
      display: block;
    }

    span.find-us {
      margin: 0 4px 0 0;
    }
  }
}

/**********************
* Main Column - Website Message
***********************/
.website-message {
  font-family: $serifFamily;
  outline: none;
  margin: 0 0 20px 0;
  text-align: center;

  @media (min-width: $custom-bp) {
    text-align: left;
  }
}

/**********************
* Main Column - International Partners
***********************/
.mypage__partner {
  border-top: 2px solid $sbf-grey;
  border-bottom: 2px solid $sbf-grey;
  padding: 24px 0 0 0;
  margin: 24px 0 48px 0;

  h2 {
    font-size: 0.75em;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 600;
    color: #909090;
    text-align: center;
    margin: 0 0 10px 0;

    @media (min-width: $x-large) {
      text-align: left;
    }
  }

  p {
    font-family: $sansSerifFamily;
    font-size: 0.825em;
  }

  a {
    @include link($sbf-blue);

    text-decoration: underline;
    font-weight: 600;
    border: none;
  }

  .partner__name {
    font-size: 1.3em;
    font-weight: 600;
    margin: 0;
    line-height: 1.3em;
  }

  .mypage__gallery {
    .mypage__img.mypage__img {
      margin: 8px 0 14px 0;

      img {
        transform: none;
      }
    }
  }

  .flag {
    display: flex;
    flex-direction: column;

    @media (min-width: $x-large) {
      flex-direction: row;
    }
  }

  .flag__image {
    padding: 0;

    @media (min-width: $x-large) {
      padding: 0 0 0 16px;
    }
  }

  .flag__body {
    display: block;
    margin: 0 auto;
    width: 275px;
    text-align: center;

    @media (min-width: $x-large) {
      margin: auto auto auto 8px;
      width: 100%;
      text-align: left;
    }
  }
}

/**********************
* Main Column - Video
***********************/
.responsive-video {
  margin: 0;
  padding: 25px 0 56.25% 0;
}

// Only Display Youtube Chat On Desktop
[data-chat-enabled="true"] {
  .youtube-chat {
    display: none;
    min-height: 360px;

    @media (min-width: $large) {
      display: block;
    }
  }
}

[data-chat-enabled="false"] {
  .youtube-chat {
    display: none;
  }
}

/**********************
* Main Column - Honored Kid Section
***********************/
.mypage__kids {
  padding: 24px 0 0 0;
  margin: 0 -12px;

  .mypage__kids__content {
    text-align: center;

    @media (min-width: $medium) {
      text-align: left;
    }

    h3,
    p {
      padding: 0 12px;
    }

    h3 {
      font-size: 1.375em;
      line-height: 1.27273;
      white-space: nowrap;
      margin: 0 0 8px 0;
    }

    p {
      font-size: 1em;
      line-height: 1.5em;
      letter-spacing: -0.015em;
    }
  }
}

/**********************
* Main Column - Individual Honored Kid
***********************/
.kid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0 12px;

  @media (min-width: 989px) {
    padding: 0;
  }
}

.kid-info {
  background: linear-gradient(to bottom, $sbf-black 87px, $sbf-white 87px);
  flex: 1 0 auto;
  width: 100%;
  margin: 12px 0;
  border: 1px solid #FFF;
  text-align: left;

  @media (min-width: 989px) {
    flex: 1 0 46%;
    max-width: 46%;
    margin: 14px 2%;
    border: 1px solid $sbf-grey;
  }

  .kid-info-inner {
    display: flex;
    flex-flow: row nowrap;

    .photo-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 87px;
      height: 87px;
      background-color: #FFF;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }

    .kid-info-content {
      flex: 1 0 47%;
      padding: 16px;
      min-width: 0;

      h3 {
        color: white;
        font-size: 1em;
        margin: 0 0 48px 0;
        padding: 16px 0 0 0;

        @extend .ellipses;
      }

      p {
        font-family: $sansSerifFamily;
        margin: 2px 0 0 -87px;
        color: #717171;
        font-size: 0.8em;

        @extend .ellipses;

        padding: 0;
      }

      .icon {
        height: 16px;
        width: 16px;
        vertical-align: middle;
        margin: 4px 4px 4px 0;
      }
    }
  }
}

@mixin small-kids {
  background: $sbf-black;

  .kid-info-inner {
    .photo-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 110px;
      background-color: #FFF;

      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
      }
    }

    .kid-info-content {
      padding: 16px 16px 0 16px;

      h3 {
        padding: 0;
        margin: 0;
        font-size: 0.9em;
      }

      p {
        color: white;
        font-size: 0.7em;
        margin: 0;

        &::before {
          content: '●';
        }
      }

      .icon { display: none; }
      .kid__location { display: none; }
    }
  }
}

.more-than-two .kid-info {
  @include small-kids;
}

@media (max-width: 989px - 1) {
  .kid-info {
    @include small-kids;
  }
}

/**********************
* Supporters Section
***********************/
.mypage__supporters {
  text-align: center;
  margin: 0 -7px;
  padding: 32px 0;

  @media (min-width: $custom-bp) {
    background: $sbf-white;
    border-top: 1px solid $sbf-grey;
    padding: 36px 0;
  }

  .mypage__img.mypage__img {
    overflow: visible;

    a {
      padding: 0;

      &:focus,
      &:active { outline: none; }
    }

    img {
      transform: none;
      height: 100%;
    }

    &:hover {
      transform: none;
      box-shadow: 0 0 0 1px #CCC, 0 11px 0 -5px rgba(0, 0, 0, 0.2);
    }

    &:hover[data-url^=http] {
      transform: translateY(-3px);
      box-shadow:
        0 0 0 1px #CCC,
        0 14px 0 -5px rgba(0, 0, 0, 0.2);
    }
  }

  .supporters__name {
    margin: 16px 0 24px 0;
    font-weight: 600;
    font-size: 14px;
  }

  a.mypage__img.mypage__img:hover {
    transform: translateY(-3px);
    box-shadow: 0 0 0 1px #CCC, 0 14px 0 -5px rgba(0, 0, 0, 0.2);
  }
}

/**********************
* Photo gallery Section
***********************/
.mypage__photo-gallery {
  text-align: center;
  margin: 0 -7px 32px -7px;
  border-top: 1px solid $sbf-grey;

  @media (min-width: $custom-bp) {
    margin: 0 -7px 36px -7px;
  }

  a {
    text-decoration: underline;
    font-weight: 600;
    border-bottom: none;
  }

  .add p {
    font-family: $sansSerifFamily;
    font-weight: 600;
    text-align: center;
  }

  .img-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
  }
}

// styles are slightly different on the main page
.mypage--main[role="main"] .mypage__photo-gallery .list-header {
  border-top: none;
  border-bottom: none;
  background: transparent;
  margin: 0 auto;
}

/**********************
*  Photo gallery Section - Overlay
***********************/
.overlay.mypage-single-photo-overlay {
  background: rgba(255, 255, 255, 0);
  padding: 0;
  margin: 12px auto 0 auto;
  box-shadow: none;
  text-align: center;
  overflow: hidden;

  @media (min-width: $medium) {
    width: 90%;
    height: 90%;
    text-align: center;
    overflow-y: hidden;
  }

  img {
    width: 100%;
    padding: 12px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  .caption-wrapper {
    background: rgba(0, 0, 0, 0.9);
    color: white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 8px;

    &.empty {
      display: none; // this gets overridden in the editable stylesheet
    }

    [contenteditable] {
      min-height: 2em;
    }
  }

  .show-next-photo,
  .show-prev-photo {
    position: absolute;
    left: 0;
    top: 48%;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    z-index: 1;
    border-radius: 0 2px 2px 0;
    font-size: 22px;
    font-weight: bold;
    padding: 6px;
  }

  .show-next-photo {
    left: auto;
    right: 0;
    border-radius: 2px 0 0 2px;
  }
}

/**********************
*  Activities Section
***********************/
.mypage__activities {
  border-top: 1px solid $sbf-grey;
  text-align: center;
  margin: 0 -7px;
  padding: 32px 0;

  @media (min-width: $medium) {
    padding: 32px 16px 32px 26px;
  }

  @media (min-width: $custom-bp) {
    padding: 36px 0;
  }

  div {
    @media (min-width: $custom-bp) {
      padding: 0 15px;
    }
  }
}

.activities__list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8em;
  text-align: center;

  li {
    width: 100%;
    padding: 16px;
    background: $sbf-white;
    border-top: none;
    border-right: none;

    @media (min-width: $small) {
      text-align: left;
      padding: 16px 16px 16px 32px;
      border: 1px solid $sbf-grey;
    }

    &::after {
      content: '';
      display: none;
      height: 26px;
      width: 26px;
      background: inherit;
      border-top: 1px solid $sbf-grey;
      border-right: 1px solid $sbf-grey;
      transform-origin: center;
      transform: translateY(-50%) rotate(45deg);
      position: absolute;
      top: 51%;
      right: -14px;
      z-index: 3;
    }

    @media (min-width: $medium + 1) {
      // Rows of three
      width: 33%;
      background: $sbf-white;
      margin: 0 0 24px 0;

      &:nth-child(3n + 3) {
        &::after {
          display: none;
        }
      }

      &:nth-child(3n + 1) {
        padding: 16px;
      }

      &:nth-child(3n + 2) {
        background: #EFEFEF;
      }

      &::after {
        display: block;
      }
    }

    @media (max-width: $small - 1) {
      &:first-child {
        border-top: 1px solid $sbf-grey;
      }

      &:nth-child(even) {
        background: #EFEFEF;
      }
    }

    @media (min-width: $small) and (max-width: $medium) {
      // Rows of two
      width: 50%;
      background: #EFEFEF;
      margin: 0 0 24px 0;

      &:nth-child(odd) {
        padding: 16px;
        background: $sbf-white;

        &::after {
          display: block;
        }
      }
    }

    &:last-child::after {
      // We never want the carat on the last element
      display: none !important;
    }

    h3,
    p {
      margin: 0;
    }

    h3 {
      font-size: 1.25em;
    }

    p {
      font-size: 1.1em;
    }

    .icon-clock {
      display: none;

      @media (min-width: $small) {
        display: inline-block;
        height: 20px;
        width: 20px;
        vertical-align: middle;
      }
    }
  }
}

// Gallery Images
.mypage__img.mypage__img {
  display: inline-block;
  height: 150px;
  width: 150px;
  background: white;
  padding: 3px;
  border: 3px solid white;
  box-shadow:
    0 0 0 1px #CCC,
    0 11px 0 -5px rgba(0, 0, 0, 0.2);
  min-width: 0;
  margin: 0 0 50% 0;

  @media (min-width: $small) {
    margin: 0 0 64px 0;
    font-size: 0.8em;
  }

  a {
    border-bottom: none;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 100%;
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow:
      0 0 0 1px #CCC,
      0 14px 0 -5px rgba(0, 0, 0, 0.2);
  }
  // don't transform the "add" buttons
  &.tk-editable-ui:hover {
    transform: none;
  }
}

.mypage__gallery { // suppporters / photo gallery #6+ images
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > .mypage__img.mypage__img {
    width: 160px;
    height: 160px;
    margin: 8px 8px 14px 8px;

    a {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;

      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
      }
    }

    a.supporters__link {
      @include link($sbf-blue);

      display: block;
      text-decoration: underline;
      font-weight: 600;
      border: none;
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      overflow: hidden;

      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
      }
    }
  }
}

.mypage--main {
  h2 {
    font-weight: 600;
  }

  h3 {
    font-size: 1.375em;
    line-height: 1.27273;
  }

  .mypage__photo-gallery {
    padding: 32px 0;

    @media (min-width: $custom-bp) {
      padding: 36px 0;
    }
  }

  .mypage__gallery { // suppporters / photo gallery #1 - 6 images

    .mypage__img {
      display: block;

      span:not(.tk-editable-ui) {
        display: block;
        width: 100%;
        height: 100%;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }

    .mypage__img:first-child:nth-last-child(1),
    .mypage__img:first-child:nth-last-child(1) ~ .mypage__img {
      @media (min-width: $medium) {
        height: 320px;
        width: 320px;
      }
    }
    // if there are only two images
    .mypage__img:first-child:nth-last-child(2),
    .mypage__img:first-child:nth-last-child(2) ~ .mypage__img {
      @media (min-width: $medium) {
        height: 320px;
        width: 320px;
      }
    }
    // if there are only three images
    .mypage__img:first-child:nth-last-child(3),
    .mypage__img:first-child:nth-last-child(3) ~ .mypage__img {
      @media (min-width: $medium) {
        height: 280px;
        width: 280px;
      }
    }
    // if there are only four images
    .mypage__img:first-child:nth-last-child(4),
    .mypage__img:first-child:nth-last-child(4) ~ .mypage__img {
      @media (min-width: $medium) {
        height: 240px;
        width: 240px;
      }
    }
    // if there are only five images
    .mypage__img:first-child:nth-last-child(5),
    .mypage__img:first-child:nth-last-child(5) ~ .mypage__img {
      @media (min-width: $medium) {
        height: 195px;
        width: 195px;
      }
    }
  }
}

.nolink {
  &:hover {
    transform: none !important;
    box-shadow:
      0 0 0 1px #CCC,
      0 11px 0 -5px rgba(0, 0, 0, 0.2) !important;
  }
}

button.load_more {
  @include link($sbf-blue);

  line-height: 1.5em;
  text-decoration: underline;
  font-weight: 600;
  border: none;

  &:focus {
    outline: none;
  }
}

.empty-filter-message {
  padding: 0.5em 0;
  border-top: 0;

  @media (min-width: $custom-bp) {
    border-top: 1px solid #CED1D6;
  }
}

span.special-char-photo-gallery {
  font-size: 24px;
  top: 4px;
  -webkit-top: 4px;
}

// Photo gallery - Internal Pages /gallery
.mypage--gallery {
  .mypage__main-column {
    @media (max-width: $custom-bp - 1) {
      padding: 10px 0;
    }
  }

  .mypage__photo-gallery {
    border-top: 0;

    @media (min-width: 679px) {
      text-align: left;
    }

    .list-header {
      margin: 30px 0;

      @media (min-width: $custom-bp) {
        margin: auto;
        padding: 0 10px;
      }
    }
  }

  .mypage__gallery {
    justify-content: center;
    padding: 10px 40px 40px 0;

    @media (min-width: $custom-bp) {
      justify-content: flex-start;
    }
  }
}

// Recent Donors - Internal Pages /donors
.mypage__main-column .fundraising__list {
  h2 + a {
    font-weight: 700;
    font-size: 0.9em;
    border-bottom: none;

    .icon {
      width: 16px;
      height: 21px;
      vertical-align: top;
      margin: 1px 2px 0 0;
    }
  }

  h3 {
    color: $sbf-blue;
  }

  ol {
    counter-reset: none;
  }

  li {
    counter-increment: none;
    font-size: 1em;
    font-weight: 700;
    padding: 10px 0;
    border-bottom: 1px solid $sbf-grey;
    display: flex;
    flex-flow: row nowrap;

    &::before {
      content: none;
    }

    &:first-child {
      border-top: 1px solid $sbf-grey;
    }

    span.dash {
      flex: 1;
    }

    span.donor__name {
      width: 80%;
    }
  }
}

// Top Teams / Top Participant / Photo Gallery Internal Pages /teams /participants /gallery
.event__lists,
.mypage__photo-gallery .inner {
  @import "sections/participant-list";
}

// Top Teams / Top Participant / Recent Donors - Internal Pages /teams /participants /donors
.event__page,
.event__lists,
.fundraising__list { // donor list
  .spinner {
    margin: 10px auto 3em auto;
  }

  .list-header {
    background-color: $sbf-white;
    border-top: 1px solid #CED1D6;
    border-bottom: 1px solid #CED1D6;
    margin: 30px -23px;

    @media (min-width: $custom-bp) {
      background: transparent;
      margin: 0;
      border: none;
    }

    &::after {
      content: "";
      display: table;
      clear: both;
    }

    .condensed-header,
    .back-to-mypage {
      text-align: center;
      display: block;
      clear: both;
    }

    .condensed-header {
      margin: 20px 0;
      font-size: 1.4em;

      @media (min-width: $custom-bp) {
        float: left;
      }
    }

    .back-to-mypage {
      margin: 20px 0;
      font-weight: 700;
      font-size: 0.9em;
      border-bottom: none;
      text-decoration: none;

      @media (min-width: $custom-bp) {
        float: left;
        margin: 15px 0;
      }

      .icon {
        width: 16px;
        height: 21px;
        vertical-align: top;
        margin: 1px 2px 0 0;
      }
    }

    .mypage__search {
      @include sbf-inline-input;

      display: block;
      margin: 25px 40px;

      @media (min-width: $custom-bp) {
        margin: 0;
        float: right;
      }

      &::after {
        content: "";
        display: table;
        clear: both;
      }

      .search_button {
        transition: transform 200ms ease-out;
        right: 10px;
        top: 25px;
      }

      .search_button .icon {
        height: 28px;
        width: 28px;
        transform: scale(0.57);
        transform-origin: center;
        transition: transform 200ms ease-out;
      }
    }
  }

  .flex__body {
    a {
      @include link($sbf-blue);

      text-decoration: underline;
      font-weight: 600;
      border: none;
    }
  }
}

// disable this until fund research link for participant mypage is done
p.fundraising--desktop:not(.donation-form-download) {
  display: none;
}

.disable-fund-research {
  display: none;
}

.private-event-tooltip-popoup {
  @include sbf-body('mini-sans');

  position: absolute;
  display: none;
  color: $sbf-black;
  background-color: #FFF;
  line-height: 16px;
  letter-spacing: -0.01px;
  top: 100px;
  left: 100px;
  max-width: 270px;
  padding: 14px;
  box-shadow: 0 8px 8px 0 #CCC;
  z-index: 50;

  @media (min-width: $custom-bp) {
    box-shadow: 0 8px 8px 0 #333;
  }
}

.private-event-tooltip-popoup::after {
  content: "";
  position: absolute;
  background-color: #FFF;
  border-right: 8px solid #FFF;
  border-top: 8px solid #FFF;
  border-left: 8px solid transparent;
  border-bottom: 8px solid transparent;
  transform: rotate(-45deg);
  bottom: -3px;
  left: calc(50% - 16px);
  z-index: 50;
}

// *------------------------------------*\
//   # Milestone Section
//\*------------------------------------*/

@import 'sections/milestones';
